<template>
  <div>
    <button
        @click="toggleDark(!isDark)"
        class="text-indigo-500 sm:block"
        title="change theme"
    >
      <SvgSun v-if="!isDark"/>
      <SvgMoon v-if="isDark"/>
    </button>
  </div>
</template>

<script setup>
import {useDark, useToggle} from "@vueuse/core";
import SvgMoon from "./svg/Moon.vue"
import SvgSun from "./svg/Sun.vue"

const isDark = useDark();
const toggleDark = useToggle(isDark);
</script>